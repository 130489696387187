import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToolbarService } from '../toolbar.service';
import { FeatureToggleService } from '../feature-toggle.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {

  constructor(private toolbarService: ToolbarService, public featureToggleService: FeatureToggleService, public authService: AuthService) {}

  ngOnInit(): void {
    this.toolbarService.displayMenuButton = false;
    if(!this.toolbarService.activeComponents.includes('landing-page')){
      this.toolbarService.activeComponents.push('landing-page')
    }
  }
  ngOnDestroy(): void {
    if(this.toolbarService.activeComponents.includes('landing-page')){
      this.toolbarService.activeComponents.splice(this.toolbarService.activeComponents.indexOf('landing-page'), 1)
    }
  }
}
