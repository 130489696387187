import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userInfoSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userGroupsSubject: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
  public loginState: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
    Auth.currentAuthenticatedUser()
    .then((user: any) => this.onUserSignedIn(user))
    .catch((error: any) => {
      console.warn(error);
    });
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      console.log(`Hub event: ${event}`)
      if (event === 'signIn') {
          const user = payload.data;
          // assign user
          this.onUserSignedIn(user);
      } else if (event === 'autoSignIn') {
        const user = payload.data;
        // assign user
        this.onUserSignedIn(user);
      }else if (event === 'autoSignIn_failure') {
          // redirect to sign in page
          this.loginState.next({state: null, payload: null});
      }
    })
  }

  isAuthorized(): boolean {
    return this.isAuthenticated();
  }

  isAuthenticated(): boolean {
    return this.userInfoSubject.getValue() != null;
  }

  isInGroup(groupnames: string[]): boolean {
    const included = groupnames.some((name) => {
      return this.userGroupsSubject.getValue()?.includes(name);
    })
    return included;
  }

  async session(): Promise<any> {
    return Auth.currentSession();
  }


  async signIn(username: string, password: string): Promise<any> {
    return Auth.signIn(username, password)
    .then((user) => {
      // console.log(user);
      // Challenges:
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.loginState.next({state: 'challenge new password', payload: user});
      } else {
        console.log('No challenge');
        // this.onUserSignedIn(user);
      }
    })
    .catch((error) => this.loginError(error, {password: password, attributes: {email: username}}));
  }

  async signOut(): Promise<void> {
    return Auth.signOut()
    .then(() => {
      this.loginState.next(null);
      this.userInfoSubject.next(null);
      this.userGroupsSubject.next(null);
    })
    .catch((error) => this.loginError(error));
  }

  async signUp(username: string, password: string, attributes: any): Promise<any> {
    return Auth.signUp({
      username,
      password,
      attributes,
      // autoSignIn: {  // Making my own autoSignIn
      //       enabled: true
      //   }
      })
    .then((signupResult) => {
      // console.log(signupResult.user);
      if (signupResult.user == null) {
        console.error('signupResult.user is null');
      } else {
        this.loginState.next({state: 'challenge signup code', payload: {password: password, attributes: {email: username}}});
      }
    })
    .catch((error) => this.loginError(error, {attributes: {email: username}}));
  }

  onUserSignedIn(user?: any): void {
    // this.loginState.next({state: 'authenticated', payload: user});
    Auth.currentUserInfo()
    .then((userInfo) => {
      this.userInfoSubject.next(userInfo);
      this.loginState.next({state: 'authenticated', payload: userInfo});
    });
    Auth.currentSession().then((session) => {
      const groups = session.getAccessToken().payload['cognito:groups'];
      this.userGroupsSubject.next(groups);
    });
  }

  async confirmSignup(username: string, code: string, password?: string): Promise<void> {
    return Auth.confirmSignUp(username, code, {})
    .then(result => {
      console.log(`confirmSignup result: ${result}`);
      switch (result) {
        case 'SUCCESS':
          // Auth.currentUserInfo()
          // .then((userInfo) => {
          //   this.onUserSignedIn(userInfo);
          // });
          console.log('signup confirmed')
          // I made my own auto sign in, when the password is known. Otherwise return to login
          if (password){
            this.signIn(username, password)
          } else {
            this.loginState.next({state: null, payload: null});
          }
          // TODO: Handle when auto-login doesn't occur
          break;
        default:
          this.loginError({code: 'InvalidSignupCode'});
      }
    })
    .catch(err => {
      this.loginError(err);
      throw err;
    });
  }

  async resendSignupCode(username: string, password?: string): Promise<void> {
    return Auth.resendSignUp(username).then(result => {
      console.log(result);
      if (result) {
          this.loginState.next({state: 'challenge signup code', payload: {password: password, attributes: {email: username}}});
      }
        else{
          this.loginError({code: 'ResendSignupCodeFailed', name: 'ResendSignupCodeFailed', message: 'Resend signup code failed'});
      }
    }).catch(err => {
      this.loginError(err);
      throw err;
    });
  }

  // async completeNewPassword(username: string, password: string, requiredAttributes: object): Promise<any> {
  async completeNewPassword(user: any, password: string, requiredAttributes: object): Promise<any> {
    return Auth.completeNewPassword(user, password, requiredAttributes)
    .then((user) => {
      // console.log(user);
      // Challenges:
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.loginState.next({state: 'challenge new password', payload: user});
      } else {
        console.log('No challenge');
        // this.onUserSignedIn(user);
      }
    })
    .catch((error) => this.loginError(error));
  }

  async forgotPassword(username: string): Promise<any> {
    return Auth.forgotPassword(username)
    .then((data) => {
      console.log('forgot password succesfully initiated');
      // console.log(data);
      this.loginState.next({state: 'forgot password', payload: {attributes: {email: username}}});
    })
    .catch((error) => this.loginError(error));
  }

  async forgotPasswordSubmit(username: string, code: string, password: string): Promise<any> {
    return Auth.forgotPasswordSubmit(username, code, password)
    .then((data) => {
      console.log('forgot password completed');
      this.signIn(username, password);
    })
    .catch((error) => this.loginError(error));
  }

  async verifyEmail(): Promise<any> {
    return Auth.verifyCurrentUserAttribute('email')
    .then((result) => {
      console.log(`initiate verify email result: ${result}`)
    })
    .catch((error) => this.loginError(error));
  }

  async verifyEmailSubmit(verificationCode: string): Promise<any> {
    return Auth.verifyCurrentUserAttributeSubmit('email', verificationCode)
    .then((result) => {
      console.log(`verify email result: ${result}`)
      this.onUserSignedIn();
    })
    .catch((error) => this.loginError(error));
  }

  async updateUserAttributes(attributes: any): Promise<void> {
    // console.log(attributes);
    const user = await Auth.currentAuthenticatedUser()
    return Auth.updateUserAttributes(user, attributes)
    .then(status => {
      console.log(status);
      this.onUserSignedIn();
    })
    .catch(err => {
      this.loginError(err);
      throw err;
    });
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const user = await Auth.currentAuthenticatedUser()
    console.log('changing password');
    return Auth.changePassword(user, oldPassword, newPassword)
    .then( data => {
      console.log(data);
    })
    .catch(err => {
      this.loginError(err);
      throw err;
    });
  }



  loginError(error: any, data?: any) {
    console.log('Login error');
    // console.log(error);
    switch (error.code) {
      case 'UserNotConfirmedException':
        this.loginState.next({state: 'challenge signup code', payload: data});
        break;
      case 'PasswordResetRequiredException':
        this.loginState.next({state: 'forgot password', payload: data});
        break;
      default:
        this.loginState.next({state: 'general error', payload: error});
        console.warn(error);
        break;
    }
  }
}
