<mat-toolbar color="primary">
    <span>
        <!-- <a routerLink="/"><img src="./assets/images/logo-banner-white.svg" class = "toolbar-logo"/></a> -->
        <!-- <a href="https://fstb.dk"><img [src]="isMobile ? './assets/images/logo-white.svg' : './assets/images/logo-banner-white.svg'" class = "toolbar-logo"/></a> -->
    </span>
    <span [ngClass]="'toolbar-title'">
        {{title}}
    </span>
    <!-- <a *ngIf="toolbarService.displayHomeButton" mat-flat-button class="toolbar-button" [ngClass]="toolbarService.activeComponents.includes('landing-page') ? 'force-primary' : 'force-primary-contrast'" routerLink="/" [color]="toolbarService.activeComponents.includes('landing-page') ? '' : 'primary'"><mat-icon>home</mat-icon></a> -->
    <a *ngIf="toolbarService.displayHomeButton" mat-flat-button class="toolbar-button" routerLink="/" [color]="toolbarService.activeComponents.includes('landing-page') ? '' : 'primary'"><mat-icon>home</mat-icon></a>
    <a *ngIf="toolbarService.displayHomeButton" mat-flat-button class="toolbar-button" routerLink="/products" [color]="toolbarService.activeComponents.includes('products') ? '' : 'primary'">Products</a>
    <div class="toolbar-spacer"></div>
    <span>
        <span *ngIf="!isMobile()">{{userFullName}}</span>
        <span *ngIf="isMobile()">{{userAbbreviation}}</span>
        <!-- <span *ngIf="!toolbarService.isMobileSubject.getValue()">{{userFullName}}</span>
        <span *ngIf="toolbarService.isMobileSubject.getValue()">{{userAbbreviation}}</span> -->
        <!-- <button mat-mini-fab [matMenuTriggerFor]="profileMenu"><mat-icon [ngClass]="isAuthenticated() ? 'material-icons' : 'material-icons-outlined' ">account_circle</mat-icon></button> -->
        <!-- <mat-menu #profileMenu="matMenu">
        <button *ngIf="authService.userInfoSubject.getValue()==null" mat-menu-item [routerLink]="['profile']">Log ind</button>
        <button *ngIf="authService.userInfoSubject.getValue()!=null" mat-menu-item [routerLink]="['profile']">Profil</button>
        <button *ngIf="authService.getGroups()!=null && authService.getGroups().includes('Treasurer')" mat-menu-item [routerLink]="['admin', 'members']">Administrer medlemmer</button>
        <button *ngIf="isAuthenticated()" mat-menu-item (click)="logout()">Log ud</button>
        </mat-menu> -->
        <button mat-mini-fab class="toolbar-button" *ngIf="toolbarService.displayMenuButton" (click)="toggleSideMenu()"><mat-icon>menu</mat-icon></button>
        <!-- <button mat-mini-fab class="toolbar-button" *ngIf="toolbarService.displayFilterButton" (click)="toggleFilterMenu()"><mat-icon>filter_alt</mat-icon></button> -->
        <!-- <button mat-mini-fab [matMenuTriggerFor]="languageMenu" class="toolbar-button" *ngIf="toolbarService.displayLanguageButton"><mat-icon>public</mat-icon></button>
        <mat-menu #languageMenu="matMenu">
            <button *ngFor="let option of languageService.languages" mat-menu-item [disabled]="option.language === languageService.languageSubject.getValue()" [routerLink]="languageService.getLink(option.language)">{{option.title}}</button>
        </mat-menu> -->
    </span>
    <!-- <button mat-flat-button *ngIf="!authService.isAuthenticated()" routerLink="/profile" class="toolbar-button force-primary-contrast" color="accent">Log ind</button>
    <button mat-flat-button *ngIf="authService.isAuthenticated()" (click)="logout()" class="toolbar-button force-primary-contrast" color="accent">Log ud</button> -->
    <button mat-flat-button *ngIf="!authService.isAuthenticated()" routerLink="/profile" class="toolbar-button" color="accent">Log in</button>
    <button mat-flat-button *ngIf="authService.isAuthenticated()" (click)="logout()" class="toolbar-button" color="accent">Log out</button>
</mat-toolbar>