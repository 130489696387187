<div class="landing-main">
    <div class="landing-main-spacer"></div>
    <!-- <div>
        Citater
    </div> -->
    <div class="landing-main-options">
        <div *ngIf="featureToggleService.isEnabled('profile')" class="landing-member-option landing-member-option-highlight">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Profile
                    </mat-card-title>
                    <!-- <mat-card-subtitle>
                    </mat-card-subtitle> -->
                </mat-card-header>
                <mat-card-content>
                    <p>Log in to and maintain user profile</p>
                </mat-card-content>
                <div class="landing-main-spacer"></div>
                <mat-card-actions>
                    <a mat-flat-button color="accent" routerLink="/profile" class="landing-full-width force-accent-contrast">Profile</a>
                </mat-card-actions>
            </mat-card>
        </div>
        <div *ngIf="featureToggleService.isEnabled('products') && authService.isAuthorized()" class="landing-member-option landing-member-option-highlight">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Products
                    </mat-card-title>
                    <!-- <mat-card-subtitle>
                    </mat-card-subtitle> -->
                </mat-card-header>
                <mat-card-content>
                    <p>Production and status of instances, items, batches and units</p>
                </mat-card-content>
                <div class="landing-main-spacer"></div>
                <mat-card-actions>
                    <a mat-flat-button color="accent" routerLink="/products" class="landing-full-width force-accent-contrast">Products</a>
                </mat-card-actions>
            </mat-card>
        </div>
        <div *ngIf="featureToggleService.isEnabled('inventory') && authService.isAuthorized()" class="landing-member-option landing-member-option-highlight">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Inventory
                    </mat-card-title>
                    <!-- <mat-card-subtitle>
                    </mat-card-subtitle> -->
                </mat-card-header>
                <mat-card-content>
                    <p>Inventory and storage of products and supplies</p>
                </mat-card-content>
                <div class="landing-main-spacer"></div>
                <mat-card-actions>
                    <a mat-flat-button color="accent" routerLink="/inventory" class="landing-full-width force-accent-contrast">Inventory</a>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
    <!-- <div>
        <p>Har du modtaget en mail med invitation til at oprette en bruger? <a routerLink="/invitation">Opret bruger her</a></p>
    </div> -->
    <div class="landing-main-spacer"></div>
</div>