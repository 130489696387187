import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout'

import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  public isMobileSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.XSmall]));
  // public highlightNavigationButtons: string[] = [];
  // public activeNavigation: string | null = null;
  private destroyed: Subject<void> = new Subject<void>()
  private subscriptions: Subscription = new Subscription();
  public activeComponents: string[] = [];
  public displayHomeButton: boolean = true;
  public displayMenuButton: boolean = false;
  // public displayFilterButton: boolean = false;
  // public displayLanguageButton: boolean = true;

  public sideMenuEnabled: boolean = true;
  // public sideFilterEnabled: boolean = true

  constructor(private breakpointObserver: BreakpointObserver) {
    this.subscriptions.add(this.breakpointObserver.observe(
      [
        Breakpoints.XLarge,
        Breakpoints.Large,
        Breakpoints.Medium,
        Breakpoints.Small,
        Breakpoints.XSmall,
      ]
    ).pipe(takeUntil(this.destroyed))
    .subscribe((state: BreakpointState) => {
      console.log(state.breakpoints)
      this.isMobileSubject.next(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall])
    }));
  }

  activateComponent(component: string, displayMenuButton?: boolean): void {
    if (displayMenuButton != null) {
      this.displayMenuButton = displayMenuButton;
    }

    if(!this.activeComponents.includes(component)){
      this.activeComponents.push(component)
    }
  }
  deactivateComponent(component: string): void {
    if(this.activeComponents.includes(component)){
      this.activeComponents.splice(this.activeComponents.indexOf(component), 1)
    }
  }
}
