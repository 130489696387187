import { v4 as uuidv4 } from 'uuid';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql/lib/types';

export const environment = {
  production: false
};

export const awsUserPool = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_wOTjzBin0',
  userPoolWebClientId: '6af6umcl87n3i976be61ln0lqc'
};

export const awsIdentityPool = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_wOTjzBin0',
  userPoolWebClientId: '5lrecsj72r2cj4r6cqmi0jj604',
  identityPoolId: 'eu-west-1:f882b09b-c38b-4a6d-bae8-c4f5bde1c1cd',
};

export const awsFactoryAppSync = {
  region: 'eu-west-1',
  endpoint: 'https://7ppi4jowhbhjhbztsnlfaeehqa.appsync-api.eu-west-1.amazonaws.com/graphql',
  authenticationType: GRAPHQL_AUTH_MODE.AWS_IAM,
};

// export const analyticsApi = {
//   url: 'https://3v14k0xl70.execute-api.eu-west-1.amazonaws.com/prod/webevents/v1/member-frontend',
//   token: '5d8ee876d84111f08991b207188c3f6cd58e09d40787133cc757baa5ff7efb2c',
//   browserSessionId: uuidv4().toString(),
// }

export const disabledFeatures = [
];