import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {

  public static disabledFeatures: string[];

  constructor() { }

  isDisabled(feature: string): boolean {
    return FeatureToggleService.disabledFeatures.includes(feature)
  }

  isEnabled(feature: string): boolean {
    return !this.isDisabled(feature)
  }
}
