import { Component, OnInit, OnDestroy } from '@angular/core';

import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout'

import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToolbarService } from '../toolbar.service';
import { AuthService } from '../auth.service';
import { FeatureToggleService } from '../feature-toggle.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy  {

  private destroyed: Subject<void> = new Subject<void>()
  private subscriptions: Subscription =  new Subscription()
  // public isMobile: boolean = this.breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.XSmall])
  public userFullName: string = '';
  public userAbbreviation: string = '';
  public title: string = 'Factory'

  constructor(private breakpointObserver: BreakpointObserver, public toolbarService: ToolbarService, public authService: AuthService, public featureToggleService: FeatureToggleService) { }

  ngOnInit(): void {
    // this.subscriptions.add(this.breakpointObserver.observe(
    //   [
    //     Breakpoints.XLarge,
    //     Breakpoints.Large,
    //     Breakpoints.Medium,
    //     Breakpoints.Small,
    //     Breakpoints.XSmall,
    //   ]
    // ).pipe(takeUntil(this.destroyed))
    // .subscribe((state: BreakpointState) => {
    //   console.log(state.breakpoints)
    //   this.toolbarService.isMobileSubject.next(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall])
    // }));
    // this.subscriptions.add(this.toolbarService.isMobileSubject.subscribe((isMobile: boolean) => {
    //   this.isMobile = isMobile;
    // }))
    this.subscriptions.add(this.authService.userInfoSubject.subscribe((userInfo: any) => {
        this.userFullName = userInfo &&
          userInfo.attributes &&
          userInfo.attributes['name'];

        if (this.userFullName) {
          this.userAbbreviation = '';
          let names: string[] = [];
          names = this.userFullName.split(' ');
          if (names.length > 0 && names[0].length > 0) {
            this.userAbbreviation += names[0].charAt(0);
          }
          if (names.length > 1 && names[names.length - 1].length > 0) {
            this.userAbbreviation += names[names.length - 1].charAt(0);
          }
        } else {
          this.userAbbreviation = '';
        }
    }));
    // this.subscriptions.add(this.activatedRoute.url.subscribe((urlSegments: UrlSegment[]) => {
    //   console.log(urlSegments)
    // }));
    // console.log(this.activatedRoute)
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.destroyed.next();
    this.destroyed.complete();
  }

  toggleSideMenu() {
    this.toolbarService.sideMenuEnabled = !this.toolbarService.sideMenuEnabled;
  }

  // toggleFilterMenu() {
  //   this.toolbarService.sideFilterEnabled = !this.toolbarService.sideFilterEnabled;
  // }

  isMobile(): boolean {
    return this.toolbarService.isMobileSubject.getValue()
  }

  logout(): void {
    this.authService.signOut();
  }

}
