import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment, awsUserPool, awsIdentityPool, awsFactoryAppSync, disabledFeatures } from "./environments/environment";

import { Auth } from '@aws-amplify/auth';
import { GraphQLAPI } from '@aws-amplify/api-graphql';

import { AppModule } from './app/app.module';
import { FeatureToggleService } from './app/feature-toggle.service';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  // Auth.configure({
  //   region: awsUserPool.region,
  //   userPoolId: awsUserPool.userPoolId,
  //   userPoolWebClientId: awsUserPool.userPoolWebClientId,
  // });

  Auth.configure({
    region: awsIdentityPool.region,
    userPoolId: awsIdentityPool.userPoolId,
    userPoolWebClientId: awsIdentityPool.userPoolWebClientId,
    identityPoolId: awsIdentityPool.identityPoolId
  });
  
  const getIdToken = async () => ({
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
  });
  
  GraphQLAPI.configure({
    aws_appsync_graphqlEndpoint: awsFactoryAppSync.endpoint,
    aws_appsync_region: awsFactoryAppSync.region,
    aws_appsync_authenticationType: awsFactoryAppSync.authenticationType,
      // OPTION 1
      // graphql_headers: getIdToken,
  
      // OPTION 2
      API: {
        graphql_headers: getIdToken
      },
  })
  
  FeatureToggleService.disabledFeatures = disabledFeatures